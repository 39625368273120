import { Container } from "./styles";
import emailIcon from "../../assets/email-icon.svg";
import phoneIcon from "../../assets/phone-icon.svg"
import { Form } from "../Form/Form";


export function Contact(){

  return(
    <Container id="Contact">
      <header>
        <h2>Want to Talk</h2>
        <p>If you've seen my potential or want to talk to me, don't hesitate to send me a message..</p>
      </header>
      <div className="contacts">
        <div>
          <img src={emailIcon} alt="Email" />
          <a href="mailto:reply2sanjeev@live.com">reply2sanjeev@live.com</a>
        </div>
        <div>
          <img src={phoneIcon} alt="Email" />
          <a href="tel:+919779547807">(91) 9779547807</a>
        </div>  
      </div>
      <Form></Form>
    </Container>
  )
}